<template>
    <img id="charImage" v-lazy="{ src: img_src, delay: 250 }" alt="character" />
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Character',
    data() {
        return {
            img_src: '',
        }
    },
    computed: {
        ...mapState({
            character: (state) => state.character,
        }),
    },
    watch: {
        '$store.state.character': {
            deep: true,
            handler: 'requestCharacter',
        },
    },
    methods: {
        async requestCharacter() {
            const char = JSON.stringify(this.character)
            const url = process.env.VUE_APP_API_URL + '&accesstoken=' + process.env.VUE_APP_API_KEY
            const self = this
            await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/vnd.api+json' },
                body: char,
            })
                .then(function (response) {
                    return response.blob()
                })
                .then(function (myBlob) {
                    self.img_src = URL.createObjectURL(myBlob)
                })
                .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log('There has been a problem with character fetch operation: ' + error.message)
                })
            localStorage.setItem('character', char)
        },
    },
}
</script>

<style></style>
