export default {
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualRag"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าหลัก"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับเรา"])},
  "visualSimulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำลองการแต่งตัว"])},
  "hairStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทรงผม"])},
  "hairColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สีผม"])},
  "clothesColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สีชุด"])},
  "searchItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหาเครื่องแต่งกาย"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนหัว"])},
  "mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนกลาง"])},
  "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนล่าง"])},
  "arrowHelperBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ลูกศร"])},
  "arrowHelperAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในการเปลี่ยนตำแหน่ง"])},
  "garment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนปีก"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ท่าทาง"])},
  "costume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชุดสวมใส่"])},
  "outfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชุดแบบที่สอง"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือก"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล้างค่า"])},
  "resetCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งตัวละคร"])},
  "aboutProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกี่ยวกับโปรเจค"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualSimulator เป็นแพลตฟอร์มที่สามารถให้เพื่อนๆ สามารถจำลองของสวมใส่ส่วนคอสตูมได้ตามใจชอบ, โปรเจคนี้ทำขึ้นมาโดยอิงจากระบบของ 'Brazil Ragnarok Online' เป็นหลัก แต่จะมีการอัพเดตจากทาง 'Thailand Ragnarok Online' ด้วยเป็นระยะ. คุณสามารถลองชุดมากกว่าร้อยชิ้น. VisualSimulator จะมีการอัพเดตรายสัปดาห์จากทาง bRO และ thRO (ถ้ามีการอัพเดต)"])},
  "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทคโนโลยีที่ใช้"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำเตือน"])},
  "warningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualSimulator ไม่ได้มีการรับรองจากทาง Gravity / Warp Portal. Ragnarok Online® is ©2002-2022 Gravity Co., Ltd. & Lee Myoungjin. โดยใช้กราฟิกจากตัวเกม (เช่น item/sprite graphics) © 2002-2022 Gravity Corp. & Lee Myoungjin."])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทีมร่วมพัฒนา"])},
  "socialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
  "mount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พาหนะ"])},
  "regularMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พาหนะประจำอาชีพ"])},
  "cashMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พาหนะเสียเงิน"])}
}