<template>
    <div>
        <input
            id="cash_mount_checkbox"
            class="form-check-input"
            type="checkbox"
            value="1"
            :checked="$store.state.cash_mount_checked"
            @change="cashMountCheck($event)"
        />
        <label class="form-check-label px-1" for="cash_mount_checkbox">{{ $t('cashMount') }}</label>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'CashMountCheckbox',
    methods: {
        ...mapMutations(['SAVE_CASH_MOUNT']),
        cashMountCheck(event) {
            this.SAVE_CASH_MOUNT(event.target.checked)
        },
    },
}
</script>

<style></style>
