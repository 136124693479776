<template>
    <div>
        <div>
            <button type="button" class="btn btn-outline-danger btn-sm m-2" @click="resetCharacter()">
                {{ $t('resetCharacter') }}
            </button>
        </div>
        <div>
            <button
                type="button"
                :class="{
                    'btn-outline-danger': validHeadTop($store.state),
                }"
                :disabled="!validHeadTop($store.state)"
                class="btn btn-sm m-2"
                @click="clickResetHeadgearTop($store.state)"
            >
                {{ $t('top') }}
            </button>
            <button
                type="button"
                :class="{
                    'btn-outline-danger': validHeadMiddle($store.state),
                }"
                :disabled="!validHeadMiddle($store.state)"
                class="btn btn-sm m-2"
                @click="clickResetHeadgearMid($store.state)"
            >
                {{ $t('mid') }}
            </button>
            <button
                type="button"
                :class="{
                    'btn-outline-danger': validHeadBot($store.state),
                }"
                :disabled="!validHeadBot($store.state)"
                class="btn btn-sm m-2"
                @click="clickResetHeadgearBot($store.state)"
            >
                {{ $t('bot') }}
            </button>
            <button
                type="button"
                :class="{
                    'btn-outline-danger': validGarment($store.state),
                }"
                :disabled="!validGarment($store.state)"
                class="btn btn-sm m-2"
                @click="clickResetHeadgearGarment($store.state)"
            >
                {{ $t('garment') }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'ClearCharacterButton',

    methods: {
        ...mapMutations([
            'RESET_CHARACTER',
            'SAVE_HEADGEAR_TOP',
            'SAVE_HEADGEAR_TOP_ITEM',
            'SAVE_HEADGEAR_MID',
            'SAVE_HEADGEAR_MID_ITEM',
            'SAVE_HEADGEAR_BOTTOM',
            'SAVE_HEADGEAR_BOTTOM_ITEM',
            'SAVE_GARMENT',
            'SAVE_GARMENT_ITEM',
        ]),
        resetCharacter() {
            this.RESET_CHARACTER(null)
            this.resetHeadgearTop()
            this.resetHeadgearMid()
            this.resetHeadgearBot()
            this.resetGarment()
        },
        clickResetHeadgearTop(item) {
            const itemFormat = JSON.parse(JSON.stringify(item))
            if (itemFormat.headgear_top_item.location?.includes('TOP')) {
                this.resetHeadgearTop()
            }
            if (itemFormat.headgear_mid_item.location?.includes('TOP')) {
                this.resetHeadgearMid()
            }
            if (itemFormat.headgear_bottom_item.location?.includes('TOP')) {
                this.resetHeadgearBot()
            }
            if (itemFormat.garment_item.location?.includes('TOP')) {
                this.resetGarment()
            }
        },
        clickResetHeadgearMid(item) {
            const itemFormat = JSON.parse(JSON.stringify(item))
            if (itemFormat.headgear_top_item.location?.includes('MID')) {
                this.resetHeadgearTop()
            }
            if (itemFormat.headgear_mid_item.location?.includes('MID')) {
                this.resetHeadgearMid()
            }
            if (itemFormat.headgear_bottom_item.location?.includes('MID')) {
                this.resetHeadgearBot()
            }
            if (itemFormat.garment_item.location?.includes('MID')) {
                this.resetGarment()
            }
        },
        clickResetHeadgearBot(item) {
            const itemFormat = JSON.parse(JSON.stringify(item))
            if (itemFormat.headgear_top_item.location?.includes('BOT')) {
                this.resetHeadgearTop()
            }
            if (itemFormat.headgear_mid_item.location?.includes('BOT')) {
                this.resetHeadgearMid()
            }
            if (itemFormat.headgear_bottom_item.location?.includes('BOT')) {
                this.resetHeadgearBot()
            }
            if (itemFormat.garment_item.location?.includes('BOT')) {
                this.resetGarment()
            }
        },
        clickResetHeadgearGarment(item) {
            const itemFormat = JSON.parse(JSON.stringify(item))
            if (itemFormat.headgear_top_item.location?.includes('GARMENT')) {
                this.resetHeadgearTop()
            }
            if (itemFormat.headgear_mid_item.location?.includes('GARMENT')) {
                this.resetHeadgearMid()
            }
            if (itemFormat.headgear_bottom_item.location?.includes('GARMENT')) {
                this.resetHeadgearBot()
            }
            if (itemFormat.garment_item.location?.includes('GARMENT')) {
                this.resetGarment()
            }
        },
        resetHeadgearTop() {
            this.SAVE_HEADGEAR_TOP(0)
            this.SAVE_HEADGEAR_TOP_ITEM({})
        },
        resetHeadgearMid() {
            this.SAVE_HEADGEAR_MID(0)
            this.SAVE_HEADGEAR_MID_ITEM({})
        },
        resetHeadgearBot() {
            this.SAVE_HEADGEAR_BOTTOM(0)
            this.SAVE_HEADGEAR_BOTTOM_ITEM({})
        },
        resetGarment() {
            this.SAVE_GARMENT(0)
            this.SAVE_GARMENT_ITEM({})
        },
        validHeadTop: (item) => {
            const itemFormat = JSON.parse(JSON.stringify(item))

            let isTop = false
            if (itemFormat.headgear_top_item.location?.includes('TOP')) {
                isTop = true
            }
            if (itemFormat.headgear_mid_item.location?.includes('TOP')) {
                isTop = true
            }
            if (itemFormat.headgear_bottom_item.location?.includes('TOP')) {
                isTop = true
            }
            if (itemFormat.garment_item.location?.includes('TOP')) {
                isTop = true
            }

            return isTop
        },
        validHeadMiddle: (item) => {
            const itemFormat = JSON.parse(JSON.stringify(item))

            let isMid = false

            if (itemFormat.headgear_top_item.location?.includes('MID')) {
                isMid = true
            }

            if (itemFormat.headgear_mid_item.location?.includes('MID')) {
                isMid = true
            }

            if (itemFormat.headgear_bottom_item.location?.includes('MID')) {
                isMid = true
            }

            if (itemFormat.garment_item.location?.includes('MID')) {
                isMid = true
            }

            return isMid
        },
        validHeadBot: (item) => {
            const itemFormat = JSON.parse(JSON.stringify(item))

            let isBot = false

            if (itemFormat.headgear_top_item.location?.includes('BOT')) {
                isBot = true
            }

            if (itemFormat.headgear_mid_item.location?.includes('BOT')) {
                isBot = true
            }

            if (itemFormat.headgear_bottom_item.location?.includes('BOT')) {
                isBot = true
            }

            if (itemFormat.garment_item.location?.includes('BOT')) {
                isBot = true
            }

            return isBot
        },
        validGarment: (item) => {
            const itemFormat = JSON.parse(JSON.stringify(item))

            let isGarment = false

            if (itemFormat.headgear_top_item.location?.includes('GARMENT')) {
                isGarment = true
            }

            if (itemFormat.headgear_mid_item.location?.includes('GARMENT')) {
                isGarment = true
            }

            if (itemFormat.headgear_bottom_item.location?.includes('GARMENT')) {
                isGarment = true
            }

            if (itemFormat.garment_item.location?.includes('GARMENT')) {
                isGarment = true
            }

            return isGarment
        },
    },
}
</script>
