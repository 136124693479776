<template>
    <div>
        <input
            id="regular_mount_checkbox"
            class="form-check-input"
            type="checkbox"
            value="1"
            :checked="$store.state.regular_mount_checked"
            @change="regularMountCheck($event)"
        />
        <label class="form-check-label px-1" for="regular_mount_checkbox">{{ $t('regularMount') }}</label>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'RegularMountCheckbox',
    methods: {
        ...mapMutations(['SAVE_REGULAR_MOUNT']),
        regularMountCheck(event) {
            this.SAVE_REGULAR_MOUNT(event.target.checked)
        },
    },
}
</script>

<style></style>
