<template>
    <ul class="ul-item-list">
        <li
            v-for="item in items"
            v-show="item.name.toLowerCase().includes(itemFilter.toLowerCase())"
            :key="item.id"
            class="li-item"
        >
            <img
                :id="item.id"
                v-lazy="{ src: `${dp_img_url}/${item.id}.png`, delay: 250 }"
                class="img-item"
                :class="{
                    'item-selected': parseInt(item.id) === $store.state.garment_item.id,
                    'item-disabled': parseInt(item.viewId) === 0,
                }"
                :viewId="item.viewId"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :alt="item.name"
                :title="item.name"
                :name="item.name"
                :item="JSON.stringify(item)"
                @click="clickItem($event)"
            />
        </li>
    </ul>
</template>

<script>
import { mapMutations } from 'vuex'
import items from '../../static/data/costume/garment.json'
export default {
    name: 'ItemListGarment',
    props: { itemFilter: { type: String, default: '' } },
    data() {
        return {
            dp_img_url: process.env.VUE_APP_ITEM_URL,
            items: items.filter(function (item) {
                return item.garment === true
            }),
            active: false,
        }
    },
    methods: {
        ...mapMutations([
            'SAVE_GARMENT',
            'SAVE_GARMENT_ITEM',
            'SAVE_HEADGEAR_TOP',
            'SAVE_HEADGEAR_TOP_ITEM',
            'SAVE_HEADGEAR_MID',
            'SAVE_HEADGEAR_MID_ITEM',
            'SAVE_HEADGEAR_BOTTOM',
            'SAVE_HEADGEAR_BOTTOM_ITEM',
        ]),
        clickItem(event) {
            this.SAVE_GARMENT(parseInt(event.target.getAttribute('viewId')))
            this.validGarment()
        },
        validGarment() {
            const itemFormat = JSON.parse(event.target.getAttribute('item'))
            const stateFormat = JSON.parse(JSON.stringify(this.$store.state))

            const database = []
            database.push(stateFormat.headgear_top_item)
            database.push(stateFormat.headgear_mid_item)
            database.push(stateFormat.headgear_bottom_item)
            database.push(stateFormat.garment_item)
            const novoItem = itemFormat

            const itensParaRemover = database.filter(
                (item) =>
                    (item?.top && novoItem.top) ||
                    (item?.middle && novoItem.middle) ||
                    (item?.bottom && novoItem.bottom) ||
                    (item?.garment && novoItem.garment)
            )

            itensParaRemover.forEach((item) => {
                const index = database.indexOf(item)
                if (index !== -1) {
                    database.splice(index, 1)
                }
            })

            const remover = itensParaRemover.filter((item) => item.id !== novoItem.id)
            database.push(novoItem)

            // remover
            for (let index = 0; index < remover.length; index++) {
                const item = remover[index]
                const removeItem = { viewId: 0 }

                if (item.top) {
                    this.updateHeadgearTop(removeItem)
                }
                if (item.middle) {
                    this.updateHeadgearMid(removeItem)
                }
                if (item.bottom) {
                    this.updateHeadgearBot(removeItem)
                }
            }

            const itemTypes = []

            if (itemFormat.top) {
                itemTypes.push('TOP')
                this.updateHeadgearTop(itemFormat)
            }
            if (itemFormat.middle) {
                itemTypes.push('MID')
                this.updateHeadgearMid(itemFormat)
            }
            if (itemFormat.bottom) {
                itemTypes.push('BOT')
                this.updateHeadgearBot(itemFormat)
            }
            if (itemFormat.garment) {
                itemTypes.push('GARMENT')
            }

            itemFormat.location = itemTypes

            this.SAVE_GARMENT_ITEM(itemFormat)
        },
        updateHeadgearTop(item) {
            this.SAVE_HEADGEAR_TOP(item.viewId)
            this.SAVE_HEADGEAR_TOP_ITEM(item)
        },
        updateHeadgearMid(item) {
            this.SAVE_HEADGEAR_MID(item.viewId)
            this.SAVE_HEADGEAR_MID_ITEM(item)
        },
        updateHeadgearBot(item) {
            this.SAVE_HEADGEAR_BOTTOM(item.viewId)
            this.SAVE_HEADGEAR_BOTTOM_ITEM(item)
        },
    },
}
</script>

<style scoped>
.ul-item-list {
    list-style: none;
    padding-top: 2px;
    padding-left: 1rem;
}

.li-item {
    float: left;
    width: 32px;
    height: 32px;
    background: url('~@/assets/img/interface/item-bg.png');
}

.img-item {
    width: 24px;
    height: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.item-selected {
    outline: 2px dashed #0d6efd;
    border-radius: 5px;
    filter: contrast(105%) brightness(110%);
}

.item-disabled {
    opacity: 0.5;
    background: url('~@/assets/img/interface/forbidden_item.png');
}
</style>
