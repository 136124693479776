<template>
    <div class="container-character">
        <div class="container">
            <div class="row">
                <div class="col-md-12 bg-gradient-ragnarok bg-gradient-ragnarok-border my-5">
                    <div class="col-md-12 card ragnarok-card">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row g-0">
                                    <div class="col-md-12 content">
                                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                                        {{ $t('visualSimulator') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-0">
                            <div class="col-md-6 p-3">
                                <div class="col-md-12">
                                    <div class="card card-job-shadow">
                                        <div class="card p-1">
                                            <div class="card-job-inner p-2">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <JobList />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card card-job-shadow">
                                        <div class="card p-1">
                                            <div class="card-job-inner p-2">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <form class="form">
                                                            <div class="input-group py-2 px-1">
                                                                <div class="input-group-text">
                                                                    <i class="fas fa-search"></i>
                                                                </div>
                                                                <input
                                                                    v-model="searchItemQuery"
                                                                    type="text"
                                                                    class="form-control"
                                                                    :placeholder="$t('searchItem')"
                                                                />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-start">
                                                    <div
                                                        id="v-pills-tab"
                                                        class="nav flex-column nav-pills"
                                                        role="tablist"
                                                        aria-orientation="vertical"
                                                    >
                                                        <button
                                                            id="v-pills-top-tab"
                                                            class="nav-link btn-tab-item active"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-top"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-top"
                                                            aria-selected="true"
                                                        >
                                                            <img
                                                                v-lazy="{ src: item_top_src, delay: 250 }"
                                                                alt="Item Top Tab"
                                                            />
                                                            <label v-if="$store.state.headgear_top_item.name !== null">
                                                                {{ $store.state.headgear_top_item.name }}
                                                            </label>
                                                            <label v-else>{{ $t('top') }}</label>
                                                            <span>{{ $t('top') }}</span>
                                                        </button>
                                                        <button
                                                            id="v-pills-mid-tab"
                                                            class="nav-link btn-tab-item"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-mid"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-mid"
                                                            aria-selected="false"
                                                        >
                                                            <img
                                                                v-lazy="{ src: item_mid_src, delay: 250 }"
                                                                alt="Item Mid Tab"
                                                            />
                                                            <label v-if="$store.state.headgear_mid_item.name !== null">
                                                                {{ $store.state.headgear_mid_item.name }}
                                                            </label>
                                                            <label v-else>{{ $t('mid') }}</label>
                                                            <span>{{ $t('mid') }}</span>
                                                        </button>
                                                        <button
                                                            id="v-pills-bot-tab"
                                                            class="nav-link btn-tab-item"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-bot"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-bot"
                                                            aria-selected="false"
                                                        >
                                                            <img
                                                                v-lazy="{ src: item_bot_src, delay: 250 }"
                                                                alt="Item Bot Tab"
                                                            />
                                                            <label
                                                                v-if="$store.state.headgear_bottom_item.name !== null"
                                                            >
                                                                {{ $store.state.headgear_bottom_item.name }}
                                                            </label>
                                                            <label v-else>{{ $t('bot') }}</label>
                                                            <span>{{ $t('bot') }}</span>
                                                        </button>
                                                        <button
                                                            id="v-pills-garment-tab"
                                                            class="nav-link btn-tab-item"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#v-pills-garment"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="v-pills-garment"
                                                            aria-selected="false"
                                                        >
                                                            <img
                                                                v-lazy="{ src: item_garment_src, delay: 250 }"
                                                                alt="Item Garment Tab"
                                                            />
                                                            <label v-if="$store.state.garment_name !== null">
                                                                {{ $store.state.garment_name }}
                                                            </label>
                                                            <label v-else>{{ $t('garment') }}</label>
                                                            <span>{{ $t('garment') }}</span>
                                                        </button>
                                                    </div>
                                                    <div id="v-pills-tabContent" class="tab-content">
                                                        <div
                                                            id="v-pills-top"
                                                            class="tab-pane tab-pane-items fade show active"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-top-tab"
                                                        >
                                                            <ItemListHeadTop :itemFilter="searchItemQuery" />
                                                        </div>
                                                        <div
                                                            id="v-pills-mid"
                                                            class="tab-pane tab-pane-items fade"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-mid-tab"
                                                        >
                                                            <ItemListHeadMid :itemFilter="searchItemQuery" />
                                                        </div>
                                                        <div
                                                            id="v-pills-bot"
                                                            class="tab-pane tab-pane-items fade"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-bot-tab"
                                                        >
                                                            <ItemListHeadBottom :itemFilter="searchItemQuery" />
                                                        </div>
                                                        <div
                                                            id="v-pills-garment"
                                                            class="tab-pane tab-pane-items fade"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-garment-tab"
                                                        >
                                                            <ItemListGarment :itemFilter="searchItemQuery" />
                                                        </div>
                                                        <div class="helper">
                                                            {{ $t('arrowHelperBefore') }}
                                                            <img
                                                                v-lazy="{
                                                                    src: require(`../assets/img/interface/arrows.png`),
                                                                    delay: 250,
                                                                }"
                                                            />
                                                            {{ $t('arrowHelperAfter') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 py-2">
                                <div class="bg-gradient-ragnarok bg-gradient-ragnarok-border">
                                    <div class="row g-0">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-center">
                                                        <GenderList />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div
                                                        class="character-box d-flex flex-column justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            id="download-icon"
                                                            src="~@/assets/img/download.svg"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            :data-bs-original-title="
                                                                'Download your ' + selected_job + ' character'
                                                            "
                                                            alt="Download"
                                                            @click="downloadImage(selected_job)"
                                                        />
                                                        <Character />
                                                    </div>
                                                    <div class="mb-3 d-flex flex-column align-items-center">
                                                        <TurnCharacter />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-center">
                                                        <BodyPalette />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h6>{{ $t('hairStyle') }}</h6>
                                                    <HeadList />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h6>{{ $t('hairColor') }}</h6>
                                                    <div class="card px-2 py-1">
                                                        <HairColorList />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row py-2 px-2">
                                    <h6>{{ $t('actions') }}</h6>
                                    <ActionList />
                                </div>
                                <div class="row py-2 px-2">
                                    <div class="col-md-6">
                                        <h6>{{ $t('costume') }}</h6>
                                        <OutfitCheckbox />
                                        <h6>{{ $t('mount') }}</h6>
                                        <RegularMountCheckbox />
                                        <CashMountCheckbox />
                                    </div>
                                    <div class="col-md-6">
                                        <h6>{{ $t('reset') }}</h6>
                                        <ClearCharacterButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { mapMutations } from 'vuex'
import dayjs from 'dayjs'
import GIF from 'gif.js'
import parseAPNG from 'apng-js'
import GenderList from '@/components/character/GenderList.vue'
import HairColorList from '@/components/character/HairColorList.vue'
import HeadList from '@/components/character/HeadList.vue'
import BodyPalette from '@/components/character/BodyPalette.vue'
import JobList from '@/components/character/JobList.vue'
import ItemListHeadTop from '@/components/character/ItemListHeadTop.vue'
import ItemListHeadMid from '@/components/character/ItemListHeadMid.vue'
import ItemListGarment from '@/components/character/ItemListGarment.vue'
import ItemListHeadBottom from '@/components/character/ItemListHeadBottom.vue'
import Character from '@/components/character/Character.vue'
import TurnCharacter from '@/components/character/TurnCharacter.vue'
import ActionList from '@/components/character/ActionList.vue'
import ClearCharacterButton from '@/components/character/ClearCharacterButton.vue'
import OutfitCheckbox from '@/components/character/OutfitCheckbox.vue'
import RegularMountCheckbox from '@/components/character/RegularMountCheckbox.vue'
import CashMountCheckbox from '@/components/character/CashMountCheckbox.vue'

export default {
    components: {
        GenderList,
        HairColorList,
        HeadList,
        BodyPalette,
        JobList,
        ItemListHeadTop,
        ItemListHeadMid,
        ItemListHeadBottom,
        ItemListGarment,
        Character,
        TurnCharacter,
        ActionList,
        ClearCharacterButton,
        OutfitCheckbox,
        RegularMountCheckbox,
        CashMountCheckbox,
    },
    data() {
        return {
            dp_img_url: process.env.VUE_APP_ITEM_URL,
            item_top_src: `${process.env.VUE_APP_ITEM_URL}/19547.png`,
            item_mid_src: `${process.env.VUE_APP_ITEM_URL}/19735.png`,
            item_bot_src: `${process.env.VUE_APP_ITEM_URL}/19783.png`,
            item_garment_src: `${process.env.VUE_APP_ITEM_URL}/20571.png`,
            selected_job: 'Novice',
            searchItemQuery: '',
        }
    },
    watch: {
        '$store.state.headgear_top_item.id': {
            deep: true,
            handler(newValue, oldValue) {
                this.item_top_src = `${process.env.VUE_APP_ITEM_URL}/${newValue}.png`
            },
        },
        '$store.state.headgear_mid_item.id': {
            deep: true,
            handler(newValue, oldValue) {
                this.item_mid_src = `${process.env.VUE_APP_ITEM_URL}/${newValue}.png`
            },
        },
        '$store.state.headgear_bottom_item.id': {
            deep: true,
            handler(newValue, oldValue) {
                this.item_bot_src = `${process.env.VUE_APP_ITEM_URL}/${newValue}.png`
            },
        },
        '$store.state.garment_item.id': {
            deep: true,
            handler(newValue, oldValue) {
                this.item_garment_src = `${process.env.VUE_APP_ITEM_URL}/${newValue}.png`
            },
        },
        '$store.state.character.jobName': {
            deep: true,
            handler(newValue, oldValue) {
                this.selected_job = `${newValue}`
            },
        },
    },
    mounted() {
        document.title = 'Ragnarok Online Visual Simulator'
        this.loadLocalStorage()
        this.activeTooltip()
        this.updatePanelUrls()

        document.addEventListener('keydown', (event) => {
            const keyName = event.key
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(keyName)) {
                event.preventDefault()
                const tabPaneItemsWidth = document.querySelector(
                    'div.tab-pane-items[id^=v-pills-].show.active'
                ).clientWidth
                const numberOfItems = document.querySelectorAll(
                    "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none'])"
                ).length
                const itemsPerRow = parseInt((tabPaneItemsWidth - 32) / 32)
                const listOfAllItems = document.querySelector(
                    "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none'])"
                )
                const selectedItem = document.querySelector(
                    'div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item img.item-selected'
                ).parentElement
                const selectedItemIndex = Array.prototype.indexOf.call(listOfAllItems.children, selectedItem)
                const itemList = document.querySelectorAll(
                    "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none'])"
                )
                if (
                    document.querySelectorAll(
                        "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none'])"
                    ).length > 0
                ) {
                    if (
                        document.querySelector(
                            "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item[style*='display: none'] img.item-selected"
                        ) != null
                    ) {
                        itemList[0].children[0].click()
                    } else if (
                        document.querySelectorAll(
                            "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none'])"
                        ).length > 0
                    ) {
                        switch (keyName) {
                            case 'ArrowUp':
                                if (selectedItemIndex >= itemsPerRow)
                                    itemList[selectedItemIndex - itemsPerRow].children[0].click()
                                break
                            case 'ArrowDown':
                                if (selectedItemIndex < numberOfItems - itemsPerRow)
                                    itemList[selectedItemIndex + itemsPerRow].children[0].click()
                                break
                            case 'ArrowLeft':
                                if (selectedItemIndex >= 1) itemList[selectedItemIndex - 1].children[0].click()
                                break
                            case 'ArrowRight':
                                if (selectedItemIndex < numberOfItems - 1)
                                    itemList[selectedItemIndex + 1].children[0].click()
                                break
                            default:
                        }
                    }

                    setTimeout(function () {
                        const divTop = document.querySelector('div.tab-pane-items[id^=v-pills-].show.active').scrollTop
                        const divHeight = document.querySelector(
                            'div.tab-pane-items[id^=v-pills-].show.active'
                        ).offsetHeight
                        const firstVisibleItem = document.querySelector(
                            "div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item:not([style*='display: none']) img"
                        ).offsetTop
                        const newSelectedItem = document.querySelector(
                            'div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item img.item-selected'
                        ).offsetTop
                        const itemHeight = document.querySelector(
                            'div.tab-pane-items[id^=v-pills-].show.active ul.ul-item-list li.li-item img.item-selected'
                        ).offsetHeight

                        if (newSelectedItem - firstVisibleItem < divTop) {
                            document.querySelector('div.tab-pane-items[id^=v-pills-].show.active').scrollTop =
                                newSelectedItem - firstVisibleItem
                        } else if (newSelectedItem + itemHeight - firstVisibleItem > divTop + divHeight) {
                            document.querySelector('div.tab-pane-items[id^=v-pills-].show.active').scrollTop =
                                newSelectedItem - firstVisibleItem + itemHeight - divHeight + 4
                        }
                    }, 10)
                }
            }
        })
    },
    methods: {
        ...mapMutations(['SAVE_CHARACTER', 'RESET_CHARACTER']),
        activeTooltip() {
            const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl)
            })
        },
        loadLocalStorage() {
            const character = localStorage.getItem('character')
            if (character != null) {
                this.SAVE_CHARACTER(JSON.parse(character))
            } else {
                this.RESET_CHARACTER()
            }
        },
        updatePanelUrls() {
            if (this.$store.state.headgear_top_item.id !== 0) {
                this.item_top_src = `${process.env.VUE_APP_ITEM_URL}/${this.$store.state.headgear_top_item.id}.png`
            }
            if (this.$store.state.headgear_mid_item.id !== 0) {
                this.item_mid_src = `${process.env.VUE_APP_ITEM_URL}/${this.$store.state.headgear_mid_item.id}.png`
            }
            if (this.$store.state.headgear_bottom_item.id !== 0) {
                this.item_bot_src = `${process.env.VUE_APP_ITEM_URL}/${this.$store.state.headgear_bottom_item.id}.png`
            }
            if (this.$store.state.garment_item.id !== 0) {
                this.item_garment_src = `${process.env.VUE_APP_ITEM_URL}/${this.$store.state.garment_item.id}.png`
            }
        },
        downloadImage(jobName) {
            const charImage = document.getElementById('charImage')
            const imageLink = document.createElement('a')
            const date = dayjs().format('YYYY-MM-DD-HHmmss')
            const gif = new GIF({
                workers: 2,
                quality: 10,
            })

            // Decode the APNG
            fetch(charImage.src)
                .then((response) => response.arrayBuffer())
                .then((buffer) => {
                    const apng = parseAPNG(buffer)
                    // Add each frame from the APNG to the GIF
                    apng.createImages().then(() => {
                        apng?.frames.forEach((frame) => {
                            gif.addFrame(frame.imageElement, { delay: frame.delay })
                        })
                        gif.on('finished', (blob) => {
                            imageLink.download = `Your-${jobName}-style-${date}.gif`
                            imageLink.href = URL.createObjectURL(blob)
                            imageLink.click()
                        })
                        gif.render()
                    })
                })
        },
    },
}
</script>
<style scoped>
.item-list {
    padding: 10px;
}

.bg-gradient-ragnarok {
    background-image: linear-gradient(
        135deg,
        rgba(231, 231, 255, 1) 0%,
        rgba(255, 231, 231, 1) 35%,
        rgba(225, 255, 254, 1) 100%
    );
    padding: 5px;
}

.bg-gradient-ragnarok-border {
    border: solid 1px #dddddd;
    border-radius: 5px;
}

.ragnarok-card {
    padding: 10px;
}

.card-job-shadow {
    -webkit-box-shadow: 0 0 2px 5px #cddefb;
    box-shadow: 0 0 2px 5px #cddefb;
}

.card-job-inner {
    border: 1px dashed #4bb1f5;
    border-radius: 6px;
}

.tab-pane-items {
    max-height: 320px;
    overflow-y: scroll;
}

.character-box {
    width: 100%;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.container-character {
    min-height: 90vh;
}

.character-box #download-icon {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}
.btn-tab-item {
    position: relative;
    width: 130px;
    min-height: 75px;
    border: 1px solid #d8d8d8 !important;
    margin-bottom: 3px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 5px;
}

.btn-tab-item img {
    width: 25px;
}

.btn-tab-item label {
    font-size: 12px;
    line-height: 1.1em;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.btn-tab-item span {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 20px;
    background: #e8e8e8;
    color: #5b5b5b;
    border-radius: 5px 0 0 5px;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.btn-tab-item.active span {
    color: #fff;
    background: var(--bs-nav-pills-link-active-bg);
}

.helper {
    border-top: 1px solid #eee;
    margin: 10px 30px 10px 1rem;
    font-size: 12px;
    padding-top: 5px;
    color: #7e7e7e;
}
</style>
