<template>
    <div class="container-body-palette">
        <h6>{{ $t('clothesColor') }}</h6>
        <ul class="ul-body-palette-list">
            <li
                v-for="(palette, index) in body_palette_list"
                :id="palette.id"
                :key="index"
                class="li-body-palette btn btn-outline-primary"
                :class="{ active: palette.id === $store.state.character.bodyPalette }"
                :title="palette.name"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                @click="clickBodyPalette($event)"
            >
                {{ palette.id }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'BodyPallet',
    data() {
        return {
            body_palette_list: [
                {
                    id: 0,
                    name: 'regular',
                },
                {
                    id: 1,
                    name: 'blue',
                },
                {
                    id: 2,
                    name: 'red',
                },
                {
                    id: 3,
                    name: 'green',
                },
            ],
        }
    },
    methods: {
        ...mapMutations(['SAVE_BODY_PALETTE']),
        clickBodyPalette(event) {
            this.SAVE_BODY_PALETTE(parseInt(event.target.getAttribute('id')))
        },
    },
}
</script>

<style scoped>
.ul-body-palette-list {
    list-style: none;
}
.li-body-palette {
    float: left;
    padding: 2px 8px;
    margin: 4px;
}
</style>
