<template>
    <div>
        <input
            id="outfit"
            class="form-check-input"
            type="checkbox"
            value="1"
            :checked="$store.state.character.outfit"
            @change="outfitCheck($event)"
        />
        <label class="form-check-label px-1" for="outfit">{{ $t('outfit') }}</label>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'OutfitCheckbox',
    methods: {
        ...mapMutations(['SAVE_OUTFIT']),
        outfitCheck(event) {
            this.SAVE_OUTFIT(event.target.checked)
        },
    },
}
</script>

<style></style>
