import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        character: {
            gender: 1,
            job: ['0'],
            jobName: 'Novice',
            head: 1,
            headPalette: 1,
            headdir: 0,
            headgear: [0, 0, 0],
            garment: 0,
            bodyPalette: 0,
            action: 0,
            canvas: '200x200+100+150',
            outfit: 0,
        },
        headgear_type: {
            isTop: false,
            isMid: false,
            isBot: false,
            isGarment: false,
        },
        headgear_top_item: {
            id: 0,
            name: '',
        },
        headgear_mid_item: {
            id: 0,
            name: '',
        },
        headgear_bottom_item: {
            id: 0,
            name: '',
        },
        garment_item: {
            id: 0,
            name: '',
        },
        cash_mount_checked: 0,
        regular_mount_checked: 0,
    },
    getters: {},
    mutations: {
        SAVE_GENDER(state, gender) {
            state.character.gender = gender
        },
        SAVE_HAIR_COLOR(state, id) {
            state.character.headPalette = id - 1
        },
        SAVE_HEAD(state, id) {
            state.character.head = id
        },
        SAVE_BODY_PALETTE(state, id) {
            state.character.bodyPalette = id
        },
        SAVE_JOB(state, id) {
            state.character.job[0] = id.toString()
        },
        SAVE_JOB_NAME(state, name) {
            state.character.jobName = name
        },
        SAVE_HEADGEAR_TOP(state, viewId) {
            state.character.headgear[1] = viewId
        },
        SAVE_HEADGEAR_TOP_ITEM(state, item) {
            state.headgear_top_item = item
        },
        SAVE_HEADGEAR_MID(state, viewId) {
            state.character.headgear[2] = viewId
        },
        SAVE_HEADGEAR_MID_ITEM(state, item) {
            state.headgear_mid_item = item
        },
        SAVE_HEADGEAR_BOTTOM(state, viewId) {
            state.character.headgear[0] = viewId
        },
        SAVE_HEADGEAR_BOTTOM_ITEM(state, item) {
            state.headgear_bottom_item = item
        },
        SAVE_GARMENT(state, viewId) {
            state.character.garment = viewId
        },
        SAVE_GARMENT_ITEM(state, item) {
            state.garment_item = item
        },
        SAVE_CHARACTER_POSITION(state, side) {
            if (side === 'left') {
                const action = (state.character.action + 1) % 8
                if (action === 0) {
                    state.character.action -= 7
                } else {
                    state.character.action++
                }
            } else {
                const action = state.character.action % 8
                if (action === 0) {
                    state.character.action += 7
                } else {
                    state.character.action--
                }
            }
        },
        SAVE_CHARACTER_HEAD(state, side) {
            const positions = [1, 0, 2]
            const currentHeadPosition = state.character.headdir
            let index = positions.findIndex((index) => index === currentHeadPosition)
            if (side === 'right') {
                if (index > 0) index--
                state.character.headdir = positions[index]
            } else {
                if (index < 2) index++
                state.character.headdir = positions[index]
            }
        },
        SAVE_ACTION(state, id) {
            const action = (state.character.action + 1) % 8
            state.character.action = id + action - 1
            if (state.character.action < 0) state.character.action = 0
        },
        SAVE_OUTFIT(state, checked) {
            if (checked) {
                state.character.outfit = 1
            } else {
                state.character.outfit = 0
            }
        },
        SAVE_CASH_MOUNT(state, checked) {
            if (checked) {
                state.cash_mount_checked = 1
            } else {
                state.cash_mount_checked = 0
            }
        },
        SAVE_REGULAR_MOUNT(state, checked) {
            if (checked) {
                state.regular_mount_checked = 1
            } else {
                state.regular_mount_checked = 0
            }
        },
        RESET_CHARACTER(state, payload) {
            state.character.gender = 1
            state.character.job = ['0']
            state.character.jobName = 'Novice'
            state.character.head = 1
            state.character.headPalette = 1
            state.character.headgear = [0, 0, 0]
            state.character.garment = 0
            state.character.bodyPalette = 0
            state.character.action = 0
            state.character.outfit = 0
            state.character.headdir = 0
        },
        SAVE_CHARACTER(state, character) {
            state.character = character
        },
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()],
})
